import * as React from 'react';
import styled from 'styled-components';

import { JobDetails } from '../../components/JobDetails';
import { Body as BaseBody } from '../../components/Typography';
import developerImage from '../../content/env-work-developer.png';

const Body = styled(BaseBody)({ maxWidth: 1000 });

const JobDetail = () => (
	<JobDetails
		name="Web Developer"
		image={developerImage}
		color="#fc984c"
		area="💻 Web Development"
		type="🧑🏼‍🎓 Working Student"
		location="📍 Stuttgart (Arena2036)"
		model="On Site & Remote"
		about={
			<Body>
				We are looking for an outstanding Web Developer to be responsible for the coding, innovative
				design and layout of our web applications. You will help building web applications from
				concept all the way to completion from the bottom up, fashioning everything from site layout
				and function.
				<br />
				<br />
				You should be able to write clean code and ensure your programs run properly. We also expect
				you to be passionate about building software and perform well working in a team, along with
				developers, engineers and web designers.
			</Body>
		}
		responsibilities={[
			'Produce well designed, testable, efficient code by using best software development practices',
			'Build website layout/user interface by using standard HTML/CSS practices',
			'Utilize data from various back-end services and databases',
			'Collect and review specifications and requirements based on technical needs',
			'Prepare and maintain software documentation',
			'Maintain, expand, and scale our site as assigned',
			' Keep up-to-date with emerging technologies and industry trends and apply them into operations and activities ',
			'Work together with web designers to match visual design intent',
		]}
		requirements={[
			'Significant experience with at least one of the following programming languages: Javascript or Typescript',
			'Good experience with web application development',
			'Excellent knowledge of how web applications work including security, session management, and best development practices',
			'Excellent knowledge of modern HTML/CSS',
			'Strong problem solving skills with a creative approach',
			'Strong organisational skills with a good ability to prioritize tasks',
			'Good ability to work and thrive in a fast-paced environment, learn rapidly and master diverse web technologies and techniques.',
		]}
		qualification={[
			'Enthusiasm for new technologies',
			'Analytical thinking',
			'Ability to abstract',
			'Ability to work independently',
			'General enthusiasm for IoT',
		]}
		offer={[
			'Family-friendly working conditions through flexible working hours',
			'The greatest possible flexibility in your choice of work location - whether in bright, modern Arena2036 or in mobile work or home offices',
			'Extensive social benefits (e.g. various special vacations and allowances)',
			'Continuous training and development opportunities through annual employee appraisals',
			'High-quality individual work equipment',
			'A subsidy for local public transport',
			'Extensive workplace health promotion measures, e.g. fruit basket, coffee, drinks, etc.',
			'Finally, a dynamic working environment with flat hierarchies and a lot of team spirit',
		]}
	/>
);

export default JobDetail;
